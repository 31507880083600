import React from 'react';

import { IGoalAction } from '../../../interfaces';
interface Props {
    indexKey: number;
    data: number;
    points: number;
    isBinary: boolean;
    disabled: boolean;
    validate: boolean;
    readonly: boolean;
    dispatch?: React.Dispatch<IGoalAction>;
}

const Loss: React.FC<Props> = ({ indexKey, data, points, isBinary, disabled, readonly, dispatch }: Props) => {
    const handleChange = (value: string) => {
        let countOfDots = 0;
        let decimals = 0;
        for (let i = 0; i < value.length; i++) {
            if (value[i] == '.') {
                countOfDots++;
                if (countOfDots > 1) {
                    return;
                }
            } else {
                const code = value.charCodeAt(i);
                if (code < 48 || code > 58) {
                    return;
                } else {
                    if (countOfDots == 1) {
                        decimals++;
                        if (decimals > 3) {
                            return;
                        }
                    }
                }
            }
        }
        let loss = value.length === 0 ? 0 : (value as unknown as number);
        loss = loss > points ? points : loss;
        dispatch &&
            dispatch({
                type: 'CHANGE_LOSS',
                key: indexKey,
                value: loss,
            });
    };
    const onBlurFunction = () => {
        if (isBinary) {
            dispatch &&
                dispatch({
                    type: 'CHANGE_LOSS',
                    key: indexKey,
                    value: data > 0 ? points : 0,
                });
        }
    };

    return (
        <input
            className={`form-control`}
            name="loss"
            type="text"
            required
            value={data}
            readOnly={readonly}
            disabled={disabled}
            onChange={(e) => {
                handleChange(e.target.value);
            }}
            onBlur={() => onBlurFunction()}
        />
    );
};

export default Loss;
