import React, { useState, useEffect, Suspense, ComponentType } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './App.scss';
import { Sidebar, Header, Auth, Loader } from './components';
import PageRoutes from './Routes';
import { getUser } from './API/Users';
import { PublicRoute, PrivateRoute } from './RouteType';
import { IContextUserDetail, IRole } from './interfaces';
import { Documentation, releaseNotes, FeedbackExportPage } from './pages';
import { convertToEpoch } from './utils';
import { useGAPageView, useSetEmployeeId } from './hooks/useGoogleAnalytics';
import ActiveSessions from './pages/activeSessions/ActiveSessions';
import { onLogout } from './API/Admin';
import ExpiryModal from './pages/activeSessions/ExpiryModel';
import { extendSession } from './API/Session';
import CONSTANTS from './constants';

const initialUserState: IContextUserDetail = {
    employeeId: 0,
    email: '',
    role: 'EMPLOYEE',
    employeeName: '',
    managerName: '',
    isManager: false,
    isExecutive: false,
    hasSubordinates: false,
};

export const EmployeeContext = React.createContext<IContextUserDetail>(initialUserState);
interface PageRoutesInterface {
    path: string;
    component: React.FC;
    name: string;
    eventKey: string;
    icon: ComponentType;
    role: IRole[];
    children: PageRoutesInterface[];
}

const GoalsRoutes: React.FC = () => {
    const [toggle, setToggle] = useState(false);
    const [user, setUser] = useState<IContextUserDetail>(initialUserState);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showExpiryModel, handleExpiryModel] = useState(false);
    const [warningTimeoutId, setWarningTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [logoutTimeoutId, setLogoutTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { triggerPageView } = useGAPageView();

    const closeModal = () => {
        handleExpiryModel(false);
    };

    useEffect(() => {
        triggerPageView(location);
    }, [navigate]);

    useEffect(() => {
        if (isAuthenticated) {
            useSetEmployeeId(user.employeeId);
            checkSessionExpiry();
        }
    }, [isAuthenticated]);
    const calculateExpiry = async (): Promise<number> => {
        let sessionExpiry;
        const lastSuccessfulApi = localStorage.getItem('lastSuccessfulApiCall');
        if (!lastSuccessfulApi) {
            console.log('could not fetch last successful');
            sessionExpiry = Math.floor(Date.now() / 1000) + CONSTANTS.SESSION_MAXAGE / 1000;
        } else {
            const lastSuccessfulApiInSeconds = Math.floor(new Date(lastSuccessfulApi).getTime() / 1000);
            sessionExpiry = lastSuccessfulApiInSeconds + CONSTANTS.SESSION_MAXAGE / 1000;
        }
        sessionExpiry = sessionExpiry - 5;
        return sessionExpiry;
    };
    const checkSessionExpiry = async () => {
        const sessionExpiry = await calculateExpiry();
        const currentTime = Math.floor(Date.now() / 1000);
        if (sessionExpiry <= currentTime) {
            await onLogout();
            return;
        }
        if (warningTimeoutId) clearTimeout(warningTimeoutId);
        if (logoutTimeoutId) clearTimeout(logoutTimeoutId);
        const timeRemaining = sessionExpiry - currentTime;
        const newWarningTimeout = setTimeout(
            async () => {
                const updatedExpiry = await calculateExpiry();
                const currentTime = Math.floor(Date.now() / 1000);
                if (updatedExpiry - currentTime > CONSTANTS.SESSION_WARNING_SECONDS) {
                    checkSessionExpiry();
                    return;
                }
                handleExpiryModel(true);
            },
            Math.max(0, (timeRemaining - CONSTANTS.SESSION_WARNING_SECONDS) * 1000),
        );

        const newLogoutTimeout = setTimeout(
            async () => {
                const updatedExpiry = await calculateExpiry();
                const currentTime = Math.floor(Date.now() / 1000);
                if (updatedExpiry <= currentTime) {
                    await onLogout();
                    return;
                } else {
                    checkSessionExpiry();
                }
            },
            Math.max(0, timeRemaining * 1000),
        );

        setWarningTimeoutId(newWarningTimeout);
        setLogoutTimeoutId(newLogoutTimeout);
    };
    const handleSessionExtension = async () => {
        await extendSession();
        await checkSessionExpiry();
        handleExpiryModel(false);
    };
    const handleAuthentication = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/v1/auth/access-token`, {
            credentials: 'include',
        });
        if (!res.ok) {
            const relativePath = window.location.href.replace(window.location.origin + '/app', '');
            localStorage.setItem('redirectTo', relativePath);
            navigate('/signin');
        }
        const data = await res.json();
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('token', data.id_token);
        const tokenStr = data.id_token;
        if (tokenStr) {
            const token = jwtDecode(tokenStr) as {
                preferred_username: string;
                exp: number;
                unique_name?: string;
            };
            if (token) {
                if (typeof token.exp !== 'undefined' && token.exp < convertToEpoch(new Date()) / 1000) {
                    setIsAuthenticated(false);
                } else {
                    const userInfo = await getUser(token.preferred_username);
                    if (userInfo) {
                        setUser({ ...userInfo });
                        setIsAuthenticated(true);
                    } else {
                        navigate(`/unauthorized`);
                        return;
                    }
                }
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        handleAuthentication();
    }, []);

    const renderRoutes = (routes: PageRoutesInterface[], isAuthenticated: boolean, userId: number): JSX.Element[] => {
        return routes
            .map((route) => {
                // Render parent route
                const parentRoute = (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            <PrivateRoute
                                component={route.component}
                                isAuthenticated={isAuthenticated}
                                userId={userId}
                            />
                        }
                    />
                );

                const childRoutes = route.children?.map((child) => (
                    <Route
                        key={child.path}
                        path={child.path}
                        element={
                            <PrivateRoute
                                component={child.component}
                                isAuthenticated={isAuthenticated}
                                userId={userId}
                            />
                        }
                    />
                ));

                return [parentRoute, ...(childRoutes || [])];
            })
            .flat();
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <EmployeeContext.Provider value={user}>
                        {isAuthenticated && (
                            <Sidebar toggleActive={toggle} toggleHandler={(value) => setToggle(value)} />
                        )}
                        <Suspense fallback={<Loader />}>
                            <div className="main">
                                {isAuthenticated && <Header toggleHandler={setToggle} />}
                                <Routes>
                                    <Route
                                        path="/signin"
                                        element={
                                            <PublicRoute
                                                component={Auth}
                                                isAuthenticated={isAuthenticated}
                                                restricted={true}
                                                handleAuthentication={handleAuthentication}
                                            />
                                        }
                                    />
                                    <Route path="/" element={<Navigate to="/dashboard" />} />
                                    {renderRoutes(PageRoutes, isAuthenticated, user.employeeId)}

                                    <Route
                                        path="/documentation"
                                        element={
                                            <PrivateRoute
                                                component={Documentation}
                                                isAuthenticated={isAuthenticated}
                                                userId={user.employeeId}
                                            />
                                        }
                                    />
                                    <Route
                                        path={`/active-sessions`}
                                        element={
                                            <PrivateRoute
                                                component={ActiveSessions}
                                                isAuthenticated={isAuthenticated}
                                                userId={user.employeeId}
                                            />
                                        }
                                    />

                                    <Route
                                        path="/releases"
                                        element={
                                            <PrivateRoute
                                                component={releaseNotes}
                                                isAuthenticated={isAuthenticated}
                                                userId={user.employeeId}
                                            />
                                        }
                                    />
                                </Routes>
                            </div>
                            {isAuthenticated && showExpiryModel && (
                                <ExpiryModal
                                    calculateExpiry={calculateExpiry}
                                    closeModal={closeModal}
                                    handleSessionExtension={handleSessionExtension}
                                />
                            )}
                        </Suspense>
                    </EmployeeContext.Provider>
                </div>
            )}
        </>
    );
};

export default GoalsRoutes;
