import React from 'react';
import { Modal } from 'react-bootstrap';
import CONSTANTS from '../../constants';
import { Table } from 'react-bootstrap';
import { IEmployeeList } from '../../interfaces/DashboardInterface';
import { formatDate, versioningStyle } from '../../utils';
import './TaskList.scss';

interface Props {
    show: boolean;
    setShow: React.Dispatch<boolean>;
    employeeList: IEmployeeList[] | undefined;
    managerId: number;
    managerName: string;
}

const TaskList: React.FC<Props> = ({ show, setShow, employeeList, managerId, managerName }: Props) => {
    const statusmap = CONSTANTS.STATUS;
    const handleClose = () => setShow(false);
    return (
        <>
            <Modal show={show} onHide={handleClose} className="modify-cycle-modal" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Employee Pending Tasks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table className="taskList" responsive size="md" borderless striped hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Quarter</th>
                                <th>Year</th>
                                <th>Status</th>
                                <th>Last Updated</th>
                                <th>Version</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeList !== undefined && employeeList.length == 0 ? (
                                <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={100}>
                                        No Employee Tasks
                                    </td>
                                </tr>
                            ) : (
                                employeeList !== undefined &&
                                employeeList.map((employeeData, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = `${process.env.PUBLIC_URL}/my-direct-reports?employee_id=${employeeData.employeeId}&quarter=${employeeData.quarter}&year=${employeeData.year}&name=${employeeData.employeeName}&m_id=${managerId}&m_name=${managerName}`;
                                            }}
                                        >
                                            <td>{employeeData.employeeName}</td>
                                            <td>{employeeData.quarter}</td>
                                            <td>{employeeData.year}</td>
                                            <td>{CONSTANTS.STATUS[employeeData.status as keyof typeof statusmap]}</td>
                                            <td> {formatDate(new Date(employeeData.updatedAt))}</td>
                                            <td>{versioningStyle(employeeData.versionNumber)}</td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TaskList;
