import { getAPIStatus, getWithAuth, postWithAuth, reloadPageForUnauthorized } from '../utils';
import { APIStatus, IFeedbackData, IFeedbackResponse } from '../interfaces';

const getFeedback = async (employeeId: number, year: number): Promise<IFeedbackResponse | undefined> => {
    try {
        const res = await getWithAuth(`${process.env.REACT_APP_API_HOST}/v1/apr-feedback/${employeeId}/${year}`);
        const response = await res.json();
        const { id, isDataAvailable, data, draft, delegated, submitted, closed, updatedAt } = response;
        return { id, isDataAvailable, data, draft, delegated, submitted, closed, updatedAt };
    } catch (err) {
        console.log(err);
        return undefined;
    }
};

const createDelegation = async (feedbackId: number): Promise<{ delegated: boolean } | undefined> => {
    try {
        const res = await postWithAuth(`${process.env.REACT_APP_API_HOST}/v1/apr-feedback/delegate/${feedbackId}`, {});
        const response = await res.json();
        const delegated = response?.delegated;
        return {
            delegated,
        };
    } catch (err) {
        console.log(err);
    }
};

const closeDelegation = async (feedbackId: number, revoke: boolean): Promise<{ delegated: boolean } | undefined> => {
    try {
        const res = await postWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/apr-feedback/close-delegation/${feedbackId}/${revoke}`,
            {},
        );
        const response = await res.json();
        const delegated = response?.delegated;
        return {
            delegated,
        };
    } catch (err) {
        console.log(err);
    }
};

const saveFeedback = async (
    employeeId: number,
    year: number,
    formData: IFeedbackData,
): Promise<IFeedbackResponse | undefined> => {
    try {
        const body = { data: formData };
        const res = await postWithAuth(`${process.env.REACT_APP_API_HOST}/v1/apr-feedback/${employeeId}/${year}`, body);
        const response = await res.json();
        const { id, isDataAvailable, data, draft, delegated, submitted, closed, updatedAt } = response;
        return { id, isDataAvailable, data, draft, delegated, submitted, closed, updatedAt };
    } catch (err) {
        console.log(err);
        return undefined;
    }
};

const submitFeedback = async (feedbackId: number): Promise<APIStatus | undefined> => {
    try {
        const res = await postWithAuth(`${process.env.REACT_APP_API_HOST}/v1/apr-feedback/submit/${feedbackId}`, {});
        if (!res.ok) {
            throw new Error(`Failed to submit feedback`);
        }
        return getAPIStatus(res.status);
    } catch (err) {
        console.log(err);
    }
};

const closeFeedback = async (feedbackId: number): Promise<APIStatus | undefined> => {
    try {
        const res = await postWithAuth(`${process.env.REACT_APP_API_HOST}/v1/apr-feedback/close/${feedbackId}`, {});
        if (!res.ok) {
            throw new Error(`Failed to submit feedback`);
        }
        return getAPIStatus(res.status);
    } catch (err) {
        console.log(err);
    }
};

const exportPDF = async (feedbackId: number): Promise<string> => {
    try {
        const res = await getWithAuth(`${process.env.REACT_APP_API_HOST}/v1/apr-feedback/export/${feedbackId}`);
        if (res?.status === 200) {
            const FileName: string = (await res.text()).replace(/"/g, '');
            return FileName || '';
        }
        return '';
    } catch (err) {
        return '';
    }
};

const getFeedbackData = async (feedbackId: number): Promise<{ goalsInfo: any; aprFeedback: any } | undefined> => {
    try {
        const response = await getWithAuth(`${process.env.REACT_APP_API_HOST}/v1/apr-feedback/${feedbackId}`);
        reloadPageForUnauthorized(response);
        const data = await response.json();
        if (response.ok) return data;
        throw new Error('Failed to fetch feedback data');
    } catch (err) {
        console.log('Error occured in getting feedback data', err);
        return undefined;
    }
};

const downloadPDF = async (fileName: string): Promise<string | undefined> => {
    try {
        const response = await getWithAuth(`${process.env.REACT_APP_API_HOST}/v1/apr-feedback/download/${fileName}`);

        if (response?.status === 200) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            return response.statusText;
        }

        if (response?.statusText === 'FAILURE') {
            return response.statusText;
        }

        return undefined;
    } catch (err) {
        console.error(err);
        return undefined;
    }
};

export {
    getFeedback,
    createDelegation,
    closeDelegation,
    saveFeedback,
    submitFeedback,
    closeFeedback,
    exportPDF,
    downloadPDF,
    getFeedbackData,
};
