import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Linkify from 'linkify-react';
interface Props {
    string: string;
    maxWords?: number;
}

import './AnnouncementDescription.scss';

const AnnouncementDescription: React.FC<Props> = ({ string, maxWords = 50 }: Props) => {
    let str = string.replace(/(^\s*)|(\s*$)/gi, '');
    str = str.replace(/[ ]{2,}/gi, ' ');
    str = str.replace(/\n /, '\n');
    const count = str.split(' ').length;
    const [showFull, setShowFull] = useState(false);
    return (
        <div className="announcement-description">
            {count < maxWords ? (
                <Linkify tagName="p" options={{ target: { url: '_blank' } }}>
                    {string}
                </Linkify>
            ) : showFull ? (
                <p>
                    {string}
                    <Button variant="link" onClick={() => setShowFull(false)}>
                        read less
                    </Button>
                </p>
            ) : (
                <p>
                    {string.substring(0, maxWords)}
                    <Button variant="link" onClick={() => setShowFull(true)}>
                        read more
                    </Button>
                </p>
            )}
        </div>
    );
};

export default AnnouncementDescription;
