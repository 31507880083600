import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import './FeedbackForm.scss';
import './FeedbackFormPDF.scss';
import { getFeedbackData } from '../../API/APRFeedback';
import { formatDate } from '../../utils';
import { IFeedbackData } from '../../interfaces';

const FeedbackFormPDF: React.FC<{ aprFeedbackId: number | null }> = ({ aprFeedbackId }) => {
    const initialFeedbackData: IFeedbackData = {
        achievements: '',
        developmentAreas: '',
        competenciesRating: '',
        specificBehaviors: '',
        customerFirstRating: '',
        integrityRating: '',
        innovationRating: '',
        teamworkRating: '',
        qualityRating: '',
        appreciation: '',
        effectiveness: '',
        overallRating: '',
        managerCommunication: '',
        employeeComments: '',
    };
    const [headerData, setHeaderData] = useState({
        dateHired: new Date(),
        employee: { firstName: '', lastName: '' },
        manager: { firstName: '', lastName: '' },
        year: new Date().getFullYear(),
    });
    const [feedbackData, setFeedbackData] = useState<IFeedbackData>(initialFeedbackData);
    const [feedbackClosed, setFeedbackClosed] = useState(false);
    let quarterWiseGoals = Array(5).fill({ status: 'NA', points: 'NA' });
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4', 'ANNUAL'];
    quarterWiseGoals = quarterWiseGoals.map((quarterWiseGoal, index) => ({
        ...quarterWiseGoal,
        quarter: quarters[index],
    }));
    const [goalsData, setGoalsData] = useState(quarterWiseGoals);

    useEffect(() => {
        const fetchFeedbackData = async () => {
            let feedback;
            if (aprFeedbackId) feedback = await getFeedbackData(aprFeedbackId);
            if (feedback) {
                setFeedbackData(feedback.aprFeedback.data);
                setFeedbackClosed(feedback.aprFeedback.closed);
                const dateHired = new Date(feedback.aprFeedback.employee.dateHired);
                setHeaderData({ ...feedback.aprFeedback, dateHired: dateHired });
                const updatedGoals = goalsData.slice();

                if (feedback.goalsInfo.count > 0) {
                    feedback.goalsInfo.rows.map((goal: any) => {
                        let index = -1;
                        if (goal.isCurrent) index = quarters.indexOf(goal.cycle.quarter);

                        if (index !== -1)
                            updatedGoals[index] = {
                                quarter: goal.cycle.quarter,
                                status: goal.status,
                                points: `${goal.totalPoints - goal.totalLoss}/${goal.totalPoints}`,
                            };
                    });
                }
                setGoalsData(updatedGoals);
            }
        };
        fetchFeedbackData();
    }, []);

    return (
        <>
            <div className="feedback-form form-pdf">
                <Form>
                    <div className="form-header">
                        <div className="section1">
                            <div className="header-items">
                                <span className="bold title">Annual Performance Review</span>
                            </div>
                        </div>
                        <div className="section2">
                            <div className="header-items">
                                <span>Employee: </span>
                                <span className="bold">{`${headerData.employee.firstName} ${headerData.employee.lastName}`}</span>
                            </div>
                            <div className="header-items">
                                <span>Date of joining: </span>
                                <span className="bold">{formatDate(headerData.dateHired)}</span>
                            </div>
                            <div className="header-items">
                                <span>Manager: </span>
                                <span className="bold">{`${headerData.manager.firstName} ${headerData.manager.lastName}`}</span>
                            </div>
                            <div className="header-items">
                                <span>Year: </span>
                                <span className="bold">{headerData.year}</span>
                            </div>
                        </div>
                    </div>
                    <div className="form-stages">
                        <div className="goals">
                            <div className="goals-heading">GOALS SCORES</div>
                            <div className="goals-content">
                                {goalsData.map((goalInfo: { quarter: string; status: string; points: string }) => (
                                    <div key={goalInfo.quarter} className="goal-item">
                                        <span className="goal-quarter">{goalInfo.quarter}</span>
                                        <span className="status">{goalInfo.status}</span>
                                        <span
                                            className={`goal-points ${
                                                goalInfo.status === 'COMPLETED' ? 'completed' : 'not-completed'
                                            }`}
                                        >
                                            {goalInfo.points}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <Table className="competencies-table">
                            <thead>
                                <tr>
                                    <th className="heading-col-1">Competencies</th>
                                    <th className="heading-col-2">Rating</th>
                                    <th className="heading-col-3">Specific Examples</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="competence-heading">Knowledge / Technical Competence</td>
                                    <td>{feedbackData.competenciesRating}/5</td>
                                    <td>
                                        <div className="with-bottom-margin">
                                            <p>What are the key achievements for the year?</p>
                                            <div className="textAreaDiv">{feedbackData.achievements}</div>
                                        </div>
                                        <div>
                                            <p>
                                                {
                                                    'What can the employee do to be more effective? (Add the key development areas.)'
                                                }
                                            </p>
                                            <div className="textAreaDiv">{feedbackData.developmentAreas}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table className="corevalues-table">
                            <thead>
                                <tr>
                                    <th className="heading-col-1">Core Values</th>
                                    <th className="heading-col-2">Rating</th>
                                    <th className="heading-col-3">
                                        Describe specific behaviors or examples demonstrating alignment to core values
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Customer First</td>
                                    <td>{feedbackData.customerFirstRating}/5</td>
                                    <td rowSpan={7} className="specific-behaviors">
                                        <p>
                                            {
                                                'What do you appreciate about the employee? (Describe specific behaviors or examples demonstrating alignment to the core values)'
                                            }
                                        </p>
                                        <div className="textAreaDiv">{feedbackData.specificBehaviors}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Integrity</td>
                                    <td>{feedbackData.integrityRating}/5</td>
                                </tr>
                                <tr>
                                    <td>Innovation</td>
                                    <td>{feedbackData.innovationRating}/5</td>
                                </tr>
                                <tr>
                                    <td>Teamwork</td>
                                    <td>{feedbackData.teamworkRating}/5</td>
                                </tr>
                                <tr>
                                    <td>Quality</td>
                                    <td>{feedbackData.qualityRating}/5</td>
                                </tr>
                            </tbody>
                        </Table>
                        {/* <Table className="feedback-table">
                            <thead>
                                <tr>
                                    <th>Manager Feedback</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="with-bottom-margin">
                                            <p>What do you appreciate about the employee?</p>
                                            <div className="textAreaDiv">{feedbackData.appreciation} </div>
                                        </div>
                                        <div>
                                            <p>What can the employee do to be more effective?</p>
                                            <div className="textAreaDiv">{feedbackData.effectiveness} </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table> */}
                        <div className={`with-bottom-margin radio-input`}>
                            <div>
                                <h6>Overall Rating:</h6>
                            </div>
                            <div className={`radio-buttons`}>
                                <p>
                                    <input
                                        type="radio"
                                        name="overallRating"
                                        value="Does not meet expectations"
                                        id="rating1"
                                        checked={feedbackData.overallRating === 'Does not meet expectations'}
                                    />
                                    <label htmlFor="rating1">Does not meet expectations</label>
                                </p>
                                <p>
                                    <input
                                        type="radio"
                                        name="overallRating"
                                        value="Meets most expectations"
                                        id="rating2"
                                        checked={feedbackData.overallRating === 'Meets most expectations'}
                                    />
                                    <label htmlFor="rating2">Meets most expectations</label>
                                </p>
                                <p>
                                    <input
                                        type="radio"
                                        name="overallRating"
                                        value="Meets expectations"
                                        id="rating3"
                                        checked={feedbackData.overallRating === 'Meets expectations'}
                                    />
                                    <label htmlFor="rating3">Meets expectations</label>
                                </p>
                                <p>
                                    <input
                                        type="radio"
                                        name="overallRating"
                                        value="Exceeds most expectations"
                                        id="rating4"
                                        checked={feedbackData.overallRating === 'Exceeds most expectations'}
                                    />
                                    <label htmlFor="rating4">Exceeds most expectations</label>
                                </p>
                                <p>
                                    <input
                                        type="radio"
                                        name="overallRating"
                                        value="Consistently exceeds expectations"
                                        id="rating5"
                                        checked={feedbackData.overallRating === 'Consistently exceeds expectations'}
                                    />
                                    <label htmlFor="rating5">Consistently exceeds expectations</label>
                                </p>
                            </div>
                        </div>
                        <div className={`with-bottom-margin acknowledgement`}>
                            <h6>Manager communicated annual performance feedback via one-on-one meeting:</h6>
                            <span>
                                <input
                                    type="radio"
                                    name="managerCommunication"
                                    value="Yes"
                                    id="yes"
                                    checked={feedbackClosed && feedbackData.managerCommunication === 'Yes'}
                                />
                                <label htmlFor="yes">Yes</label>
                            </span>
                            <span>
                                <input
                                    type="radio"
                                    name="managerCommunication"
                                    value="No"
                                    id="no"
                                    checked={feedbackClosed && feedbackData.managerCommunication === 'No'}
                                />
                                <label htmlFor="no">No</label>
                            </span>
                        </div>
                        <div className={`employee-comments with-bottom-margin`}>
                            <h6>Employee comments</h6>
                            <div className="textAreaDiv">{feedbackClosed && feedbackData.employeeComments} </div>
                        </div>
                    </div>
                </Form>
            </div>
            <footer className="footer">{new Date().toString()}</footer>
        </>
    );
};

export default FeedbackFormPDF;
