import React from 'react';
import FeedbackFormPDF from './FeedbackFormPDF';
import { useParams } from 'react-router-dom';

const FeedbackExportPage: React.FC = () => {
    const { aprFeedbackId } = useParams<{ aprFeedbackId?: string }>();
    let finalAprFeedbackId = null;
    if (aprFeedbackId) finalAprFeedbackId = aprFeedbackId ? parseInt(aprFeedbackId, 10) : null;
    return (
        <>
            <FeedbackFormPDF aprFeedbackId={finalAprFeedbackId} />
        </>
    );
};

export default FeedbackExportPage;
