import React, { useContext, useState } from 'react';
import { Accordion, Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { X } from 'react-bootstrap-icons';
import { checkAccessForPage } from '../../utils';

import './sidebar.scss';
import pageRoutes from '../../Routes';
import { LogoSvg, NextArrowSvg } from '../../icons';
import { EmployeeContext } from '../../GoalsRoutes';
interface Props {
    toggleActive: boolean;
    toggleHandler: (value: boolean) => void;
}

const Sidebar: React.FC<Props> = ({ toggleActive, toggleHandler }: Props) => {
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const employee = useContext(EmployeeContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <div className={`sidebar ${toggleActive ? 'active' : ''}`}>
            <div className="toggle" onClick={() => toggleHandler(false)}>
                <X />
            </div>
            <div className="logo">
                <LogoSvg />
            </div>
            <Nav className="flex-column ml-auto nav-scroll">
                {pageRoutes.map((routes, index) => {
                    if (checkAccessForPage(employee.role, routes.role, employee.isManager, employee.isExecutive)) {
                        return (
                            <LinkContainer key={index} to={routes.path}>
                                {routes.children.length == 0 ? (
                                    <Nav.Link
                                        active={routes.path === pathname}
                                        href={routes.path}
                                        eventKey={routes.eventKey}
                                        onClick={() => toggleHandler(false)}
                                    >
                                        <span>
                                            <routes.icon></routes.icon>
                                        </span>
                                        <span className="route-name">{routes.name}</span>
                                    </Nav.Link>
                                ) : (
                                    <Accordion
                                        bsPrefix={`custom-accordion ${accordionExpanded ? 'active' : ''}`}
                                        defaultActiveKey="1"
                                    >
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header
                                                onClick={() => setAccordionExpanded(!accordionExpanded)}
                                                className="nav-sub-menu nav-link"
                                            >
                                                <span>
                                                    <routes.icon />
                                                </span>
                                                <span className="route-name">
                                                    {routes.name}
                                                    <NextArrowSvg className={`${accordionExpanded ? 'rotate' : ''}`} />
                                                </span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    {routes.children.map((childRoute, childIndex) => (
                                                        <Nav.Link
                                                            active={childRoute.path === pathname}
                                                            key={`nav-${childIndex}`}
                                                            eventKey={childRoute.eventKey}
                                                            onClick={() => {
                                                                navigate(childRoute.path);
                                                                toggleHandler(false);
                                                            }}
                                                        >
                                                            <span>
                                                                <childRoute.icon />
                                                            </span>
                                                            <span className="route-name">{childRoute.name}</span>
                                                        </Nav.Link>
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )}
                            </LinkContainer>
                        );
                    }
                })}
            </Nav>
        </div>
    );
};

export default Sidebar;
