import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ICategorySummary } from '../../interfaces/DashboardInterface';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import './TeamCategory.scss';

interface Props {
    dataset: ICategorySummary[];
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TeamCategory: React.FC<Props> = ({ dataset }: Props) => {
    const data = {
        labels: ['Targets', 'Scores'],
        datasets: dataset,
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        indexAxis: 'y' as const,
        scales: {
            y: {
                stacked: true,
                beginAtZero: true,
                min: 0,
                ticks: {
                    stepSize: 0.1,
                },
                grid: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: true,
                interaction: {
                    mode: 'nearest',
                },
            },
            legend: {
                display: true,
                labels: {
                    boxWidth: 10,
                    font: {
                        size: 10,
                    },
                    usePointStyle: true,
                },
            },
        },
    };

    return (
        <div className="team-category">
            <Bar data={data} width={100} height={100} options={options} />
        </div>
    );
};

export default TeamCategory;
