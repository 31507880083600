import { ICategorySummary, ICycleInfo, IEmployeeList } from './../interfaces/DashboardInterface';
import { IStatusWithResponse, ICategoryTypes, IOwnerSelectFilter } from '../interfaces';
import { IGoals, IQuarterType, IGoalSaveType, IGoal, IGoalsSearch, APIStatus, IManagerSummary } from '../interfaces';
import CONSTANTS from '../constants';
import queryString from 'qs';
import {
    getWithAuth,
    formatNumber,
    getAPIStatus,
    getUserFullName,
    reloadPageForUnauthorized,
    postWithAuth,
    putWithAuth,
    deleteWithAuth,
} from '../utils';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const getReviwer = async (goalObj: any): Promise<string> => {
    try {
        if (goalObj.status === 'TARGETS_EXECUTIVE_REVIEW' || goalObj.status === 'SCORES_EXECUTIVE_REVIEW') {
            const res = await getWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/goals/executive-reviewer?goalId=${goalObj.id}`,
            );
            if (!res.ok) {
                // Handle error if the response is not successful
                console.error('Failed to fetch executive reviewer details');
                return '';
            }
            const executivereviwer = await res.json();
            if (executivereviwer) {
                const response2: any = await getWithAuth(
                    `${process.env.REACT_APP_API_HOST}/v1/users/by-userId?id=${executivereviwer}`,
                );
                const executiveApproverDetails = await response2.json();
                if (executiveApproverDetails?.firstName && executiveApproverDetails?.lastName)
                    return getUserFullName(executiveApproverDetails.firstName, executiveApproverDetails.lastName);
            }
            return '';
        } else if (
            goalObj.status === 'TARGETS_DRAFT' ||
            goalObj.status === 'TARGETS_WITHDRAWN' ||
            goalObj.status === 'TARGETS_REVISION' ||
            goalObj.status === 'TARGETS_REVIEW' ||
            goalObj.status === 'SCORES_REVIEW'
        ) {
            return getUserFullName(goalObj.reviewer.firstName, goalObj.reviewer.lastName);
        } else if (
            goalObj.status === 'TARGETS_REJECTED' ||
            goalObj.status === 'TARGETS_APPROVED' ||
            goalObj.status === 'REVISE_REVERTED' ||
            goalObj.status === 'SCORES_WITHDRAWN'
        ) {
            const response: any = await getWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/users/by-userId?id=${goalObj.targetsApprovedById}`,
            );
            if (!response.ok) {
                console.error('Failed to fetch targets approver details');
                return '';
            }
            const targetsApproverDetails = await response.json();
            if (targetsApproverDetails?.firstName && targetsApproverDetails?.lastName)
                return getUserFullName(targetsApproverDetails['firstName'], targetsApproverDetails['lastName']);
            return '';
        } else {
            const response = await getWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/users/by-userId?id=${goalObj.scoresApprovedById}`,
            );
            if (!response.ok) {
                console.error('Failed to fetch score approver details');
                return '';
            }
            const scoresApproverDetails = await response.json();
            if (scoresApproverDetails?.firstName && scoresApproverDetails?.lastName)
                return getUserFullName(scoresApproverDetails['firstName'], scoresApproverDetails['lastName']);
            return '';
        }
    } catch (error) {
        return ''; //in case error
    }
    return ''; // default
};

const getGoals = async (
    employeeId?: number,
    year?: number,
    quarter?: string,
    type?: string,
): Promise<IGoals[] | undefined> => {
    try {
        const queryParams = {
            employeeId: employeeId ? employeeId : '',
            year: year ? year : '',
            quarter: quarter ? quarter : '',
            type: type ? type : '',
        };
        const goalsResponse = await getWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/goals?${queryString.stringify(queryParams)}`,
        );
        reloadPageForUnauthorized(goalsResponse);
        const response: IGoals[] = [];
        const goalsObj = await goalsResponse.json();
        const cycles = ['Q1', 'Q2', 'Q3', 'Q4', 'ANNUAL'];
        for (const cycle of cycles) {
            response.push({
                goalId: 0,
                date: new Date('invalid'),
                points: 0,
                score: 0,
                quarter: cycle as IQuarterType,
                status: 'N/A',
                approver: '',
                version: 0,
                submissionDate: new Date('invalid'),
            });
        }
        if (goalsObj) {
            const { rows } = goalsObj;
            for (const goal of rows) {
                const index = response.findIndex((r) => r.quarter == goal.cycle.quarter);
                response[index] = {
                    goalId: goal.id,
                    date: new Date(parseInt(goal.updatedAt)),
                    points: goal.totalPoints,
                    score: goal.totalPoints - goal.totalLoss,
                    quarter: goal.cycle.quarter,
                    status: goal.goalStatus,
                    approver: await getReviwer(goal),
                    version: goal.versionNumber,
                    submissionDate: new Date(parseInt(goal.cycle.endDate)),
                    cycleInfo: goal.cycle,
                    allowRevise: goal.allowRevise,
                };
            }
        }

        return response;
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getGoalCycleInfo = async (
    goalId: number,
    employeeId: number,
    year: number,
    quarter: string,
    type?: string,
): Promise<{ cycle: ICycleInfo; allowRevise: boolean } | undefined> => {
    const goalCycleInfo = await getGoals(employeeId, year, quarter, type);
    const found = goalCycleInfo?.find((element) => element.goalId === goalId);
    if (found) {
        const cycelInfo = {
            cycleId: found.cycleInfo.id,
            year: found.cycleInfo.year,
            quarter: found.cycleInfo.quarter,
            startDate: found.cycleInfo.startDate,
            endDate: found.cycleInfo.endDate,
            scoreEndDate: found.cycleInfo.scoringEndDate,
            scoreStartDate: found.cycleInfo.scoringStartDate,
            targetEndDate: found.cycleInfo.settingEndDate,
            targetStartDate: found.cycleInfo.settingStartDate,
            isStarted: found.cycleInfo.isStarted,
            runningStatus: found.cycleInfo.runningStatus,
            isExecutiveApproval: found.cycleInfo.isExecutiveApproval,
        };
        const checkallowRevise = found.allowRevise;
        if (checkallowRevise !== undefined) {
            return { cycle: cycelInfo, allowRevise: checkallowRevise };
        }
    }

    return undefined;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getGoalsList = async (goalId: number): Promise<IGoal[] | undefined> => {
    try {
        const goalsResponse = await getWithAuth(`${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/items`);
        reloadPageForUnauthorized(goalsResponse);
        const goals = await goalsResponse.json();
        const goalsData: IGoal[] = [];
        for (const goal of goals) {
            const ownerList: IOwnerSelectFilter[] = [];
            const { owner } = goal;
            for (const ownerInfo of owner) {
                if (ownerInfo.owner !== null && ownerInfo.ownerId !== null) {
                    ownerList.push({
                        value: ownerInfo.ownerId,
                        label: getUserFullName(ownerInfo.owner.firstName, ownerInfo.owner.lastName),
                        email: ownerInfo.owner.email,
                    });
                }
            }
            goalsData.push({
                goalId: goalId,
                goalItemId: goal.id,
                categoryId: goal.categoryId,
                category: goal.category.name,
                ownerId: goal.ownerId,
                points: goal.points,
                isPrivate: goal.isPrivate,
                target: goal.target,
                fullTarget: goal.fullTarget,
                zeroTarget: goal.zeroTarget,
                owner: ownerList,
                scoreId: goal.score.id,
                loss: goal.score.loss,
                completionProof: goal.score.completionProof ? goal.score.completionProof : '',
                binaryTarget: goal.isBinary,
                weight: goal.weight,
                isWeightChanged: false,
            });
        }

        return goalsData;
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createGoal = async (goalId: number, formData: any): Promise<APIStatus | undefined> => {
    try {
        const goalsResponse = await postWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/items`,
            formData,
        );
        reloadPageForUnauthorized(goalsResponse);
        return getAPIStatus(goalsResponse.status);
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateGoal = async (goalId: number, formData: any): Promise<APIStatus | undefined> => {
    try {
        const goalItemId = 0;
        const goalsResponse = await putWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/items/${goalItemId}`,
            formData,
        );
        reloadPageForUnauthorized(goalsResponse);
        return getAPIStatus(goalsResponse.status);
    } catch (err) {
        console.log(err);
    }
};

const deleteGoal = async (goalId: number, goalItemId: number[]): Promise<APIStatus | undefined> => {
    try {
        const goalsResponse = await deleteWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/items/${goalItemId.toString()}`,
        );
        reloadPageForUnauthorized(goalsResponse);
        return getAPIStatus(goalsResponse.status);
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateScore = async (goalId: number, formData: any) => {
    try {
        for (const data of formData) {
            const bodyData = {
                loss: data.loss,
                completionProof: data.completionProof,
                goalId: goalId,
                goalItemId: data.goalItemId,
                id: data.scoreId,
            };
            const response = await putWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/items/${data.goalItemId}/score/${data.scoreId}`,
                bodyData,
            );
            reloadPageForUnauthorized(response);
        }
    } catch (err) {
        console.log(err);
    }
};

const submitGoals = async (goalId: number, type: IGoalSaveType): Promise<APIStatus | undefined> => {
    try {
        if (type !== 'N/A') {
            const saveType = type === 'GOAL' ? 'targets' : 'scores';
            const response = await postWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/process/${saveType}`,
                {},
            );
            reloadPageForUnauthorized(response);
            return getAPIStatus(response.status);
        }
    } catch (err) {
        console.log(err);
    }
};

// withdraw targets or scores
const withdraw = async (goalId: number, type: IGoalSaveType, workflow: string): Promise<APIStatus | undefined> => {
    try {
        if (type !== 'N/A') {
            const saveType = type === 'GOAL' ? 'targets' : 'scores';
            const response = await postWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/process/${saveType}/withdraw`,
                { workflow: workflow },
            );
            reloadPageForUnauthorized(response);
            return getAPIStatus(response.status);
        }
    } catch (err) {
        console.log(err);
    }
};

const approveGoals = async (goalId: number, type: IGoalSaveType): Promise<APIStatus | undefined> => {
    try {
        if (type !== 'N/A') {
            const saveType = type === 'GOAL' ? 'targets' : 'scores';
            const response = await postWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/process/${saveType}/approval`,
                {},
            );
            reloadPageForUnauthorized(response);
            return getAPIStatus(response.status);
        }
    } catch (err) {
        console.log(err);
    }
};

const rejectGoals = async (goalId: number, type: IGoalSaveType): Promise<APIStatus | undefined> => {
    try {
        if (type !== 'N/A') {
            const saveType = type === 'GOAL' ? 'targets' : 'scores';
            const response = await postWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/process/${saveType}/rejection`,
                {},
            );
            reloadPageForUnauthorized(response);
            return getAPIStatus(response.status);
        }
    } catch (err) {
        console.log(err);
    }
};

const approveExecutiveGoals = async (goalId: number, type: IGoalSaveType): Promise<APIStatus | undefined> => {
    try {
        if (type !== 'N/A') {
            const saveType = type === 'GOAL' ? 'targets' : 'scores';
            const response = await postWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/process/${saveType}/executive-approval`,
                {},
            );
            reloadPageForUnauthorized(response);
            return getAPIStatus(response.status);
        }
    } catch (err) {
        console.log(err);
    }
};

const rejectExecutiveGoals = async (goalId: number, type: IGoalSaveType): Promise<APIStatus | undefined> => {
    try {
        if (type !== 'N/A') {
            const saveType = type === 'GOAL' ? 'targets' : 'scores';
            const response = await postWithAuth(
                `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/process/${saveType}/executive-rejection`,
                {},
            );
            reloadPageForUnauthorized(response);
            return getAPIStatus(response.status);
        }
    } catch (err) {
        console.log(err);
    }
};

const getGoalsForSearch = async (
    pageNumber: number,
    search?: string,
    year?: number,
    quarter?: string,
    limit = 10,
): Promise<{ goals: IGoalsSearch[]; count: number } | undefined> => {
    try {
        const queryParams = {
            Text: search ? search : '',
            year: year ? year : '',
            quarter: quarter ? quarter : '',
            offset: (pageNumber - 1) * limit,
            limit: limit,
            status: 'COMPLETED,TARGETS_APPROVED',
        };
        const goalsResponse = await getWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/goals?${queryString.stringify(queryParams)}`,
        );
        reloadPageForUnauthorized(goalsResponse);
        const goalsObj = await goalsResponse.json();
        const response: IGoalsSearch[] = [];
        if (goalsObj) {
            const { count, rows } = goalsObj;
            for (const goal of rows) {
                response.push({
                    goalId: goal.id,
                    date: new Date(parseInt(goal.updatedAt)),
                    status: goal.goalStatus,
                    employeeEmail: goal?.employee?.email ? goal.employee.email : '',
                    employeeId: goal.employeeId,
                    employeeName: goal?.employee ? `${goal.employee.firstName} ${goal.employee.lastName}` : '',
                    managerName: goal?.reviewer ? `${goal.reviewer.firstName} ${goal.reviewer.lastName}` : '',
                    quarter: goal.cycle.quarter,
                    year: goal.cycle.year,
                    score: goal.totalPoints - goal.totalLoss,
                    eoqResults: goal.eoqResults,
                });
            }
            return { goals: response, count: count };
        }
    } catch (err) {
        console.log(err);
    }
};

const getManagerSummary = async (
    managerId: number,
    year: number,
    quarter: IQuarterType,
): Promise<IManagerSummary | undefined> => {
    try {
        const queryParams = {
            manager_id: managerId,
            year: year,
            quarter: quarter,
        };
        const summaryResponse = await getWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/reports/manager-summary?${queryString.stringify(queryParams)}`,
        );
        reloadPageForUnauthorized(summaryResponse);

        const summary = await summaryResponse.json();
        const { reporteesPrev, reporteesCurrent, targets, scores } = summary;

        const dataSet: ICategorySummary[] = [];

        if (targets.summary.length > 0) {
            for (const summary of targets.summary) {
                const category = summary.category as ICategoryTypes;
                dataSet.push({
                    backgroundColor: CONSTANTS.CATEGORY_COLORS[category] ? CONSTANTS.CATEGORY_COLORS[category] : 'red',
                    data: [formatNumber(summary.average)],
                    label: summary.category,
                });
            }
        }

        if (scores.summary.length > 0) {
            for (const summary of scores.summary) {
                const category = summary.category as ICategoryTypes;
                const index = dataSet.findIndex((d) => d.label == summary.category);
                if (index < 0) {
                    dataSet.push({
                        backgroundColor: CONSTANTS.CATEGORY_COLORS[category]
                            ? CONSTANTS.CATEGORY_COLORS[category]
                            : CONSTANTS.COLORS.PRIMARY_COLOR,
                        data: [0, formatNumber(summary.average)],
                        label: summary.category,
                    });
                } else {
                    dataSet[index].data.push(summary.average);
                }
            }
        }
        const employeeTargetDataSet: IEmployeeList[] = [];
        const employeeScoreDataSet: IEmployeeList[] = [];

        for (const employeeData of reporteesCurrent.employeeList) {
            if (
                employeeData.status === 'TARGETS_DRAFT' ||
                employeeData.status === 'TARGETS_REJECTED' ||
                employeeData.status === 'TARGETS_REVISION' ||
                employeeData.status === 'TARGETS_WITHDRAWN'
            ) {
                employeeTargetDataSet.push({
                    employeeId: employeeData.id,
                    employeeName: employeeData.employee_name,
                    quarter: employeeData.quarter,
                    status: employeeData.status,
                    year: employeeData.year,
                    versionNumber: employeeData.version_number,
                    updatedAt: employeeData.updated_at,
                });
            }
        }

        for (const employeeData of reporteesPrev.employeeList) {
            if (
                employeeData.status === 'TARGETS_APPROVED' ||
                employeeData.status === 'REVISE_REVERTED' ||
                employeeData.status === 'SCORES_WITHDRAWN' ||
                employeeData.status === 'SCORES_REJECTED'
            )
                employeeScoreDataSet.push({
                    employeeId: employeeData.id,
                    employeeName: employeeData.employee_name,
                    quarter: employeeData.quarter,
                    status: employeeData.status,
                    year: employeeData.year,
                    versionNumber: employeeData.version_number,
                    updatedAt: employeeData.updated_at,
                });
        }

        const managerSummary: IManagerSummary = {
            totalEmployeesPrev: 0,
            totalEmployeesCurrent: 0,
            withPendingScores: 0,
            withPendingTarget: 0,
            categoryDataset: dataSet,
            employeeTargetList: employeeTargetDataSet,
            employeeScoreList: employeeScoreDataSet,
        };

        if (reporteesPrev?.summary.length > 0) {
            for (const summary of reporteesPrev.summary) {
                managerSummary.totalEmployeesPrev += summary.count;
                if (
                    summary.status === 'TARGETS_APPROVED' ||
                    summary.status === 'REVISE_REVERTED' ||
                    summary.status === 'SCORES_WITHDRAWN' ||
                    summary.status === 'SCORES_REJECTED'
                ) {
                    managerSummary.withPendingScores += summary.count;
                }
            }
        }

        if (reporteesCurrent?.summary.length > 0) {
            for (const summary of reporteesCurrent.summary) {
                managerSummary.totalEmployeesCurrent += summary.count;
                if (
                    summary.status === 'TARGETS_DRAFT' ||
                    summary.status === 'TARGETS_REJECTED' ||
                    summary.status === 'TARGETS_REVISION' ||
                    summary.status === 'TARGETS_WITHDRAWN'
                ) {
                    managerSummary.withPendingTarget += summary.count;
                }
            }
        }

        return managerSummary;
    } catch (err) {
        console.log(err);
    }
};

const createVersion = async (goalId: number): Promise<APIStatus | undefined> => {
    try {
        const response = await postWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/process/targets/revision`,
            {},
        );
        reloadPageForUnauthorized(response);
        return getAPIStatus(response.status);
    } catch (err) {
        console.log(err);
    }
};

const revertVersion = async (goalId: number): Promise<APIStatus | undefined> => {
    try {
        const response = await postWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/process/targets/revert`,
            {},
        );
        reloadPageForUnauthorized(response);
        return getAPIStatus(response.status);
    } catch (err) {
        console.log(err);
    }
};

const getExecutiveSummary = async (
    executiveId: number,
    year: number,
    quarter: IQuarterType,
): Promise<IManagerSummary | undefined> => {
    try {
        const queryParams = {
            executive_id: executiveId,
            year: year,
            quarter: quarter,
        };
        const summaryResponse = await getWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/reports/executive-summary?${queryString.stringify(queryParams)}`,
        );
        reloadPageForUnauthorized(summaryResponse);

        const summary = await summaryResponse.json();
        const { reporteesPrev, reporteesCurrent, targets, scores } = summary;

        const dataSet: ICategorySummary[] = [];

        if (targets.summary.length > 0) {
            for (const summary of targets.summary) {
                const category = summary.category as ICategoryTypes;
                dataSet.push({
                    backgroundColor: CONSTANTS.CATEGORY_COLORS[category] ? CONSTANTS.CATEGORY_COLORS[category] : 'red',
                    data: [formatNumber(summary.average)],
                    label: summary.category,
                });
            }
        }

        if (scores.summary.length > 0) {
            for (const summary of scores.summary) {
                const category = summary.category as ICategoryTypes;
                const index = dataSet.findIndex((d) => d.label == summary.category);
                if (index < 0) {
                    dataSet.push({
                        backgroundColor: CONSTANTS.CATEGORY_COLORS[category]
                            ? CONSTANTS.CATEGORY_COLORS[category]
                            : CONSTANTS.COLORS.PRIMARY_COLOR,
                        data: [0, formatNumber(summary.average)],
                        label: summary.category,
                    });
                } else {
                    dataSet[index].data.push(summary.average);
                }
            }
        }

        const managerSummary: IManagerSummary = {
            totalEmployeesPrev: 0,
            totalEmployeesCurrent: 0,
            withPendingScores: 0,
            withPendingTarget: 0,
            categoryDataset: dataSet,
        };

        if (reporteesPrev?.summary.length > 0) {
            for (const summary of reporteesPrev.summary) {
                managerSummary.totalEmployeesPrev += summary.count;
                if (summary.status === 'TARGETS_APPROVED') {
                    managerSummary.withPendingScores += summary.count;
                }
            }
        }

        if (reporteesCurrent?.summary.length > 0) {
            for (const summary of reporteesCurrent.summary) {
                managerSummary.totalEmployeesCurrent += summary.count;
                if (
                    summary.status === 'TARGETS_DRAFT' ||
                    summary.status === 'TARGETS_REJECTED' ||
                    summary.status === 'TARGETS_REVISION'
                ) {
                    managerSummary.withPendingTarget += summary.count;
                }
            }
        }

        return managerSummary;
    } catch (err) {
        console.log(err);
    }
};

const copyGoals = async (
    goalId: number,
    formData: { employeeId: number; year: number; quarter: IQuarterType },
): Promise<IStatusWithResponse | undefined> => {
    try {
        const response = await postWithAuth(`${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/copy`, formData);
        reloadPageForUnauthorized(response);
        return { status: getAPIStatus(response.status), response: response };
    } catch (err) {
        console.log(err);
    }
};

const logAction = async (
    goalId: number,
    data: {
        employeeId: number;
        year: number;
        quarter: IQuarterType;
        action: string;
        response: string;
        logType: string;
    },
) => {
    try {
        const goalsResponse = await postWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/log-action`,
            data,
        );
        reloadPageForUnauthorized(goalsResponse);
        return getAPIStatus(goalsResponse.status);
    } catch (err) {
        console.log(err);
    }
};

export {
    getGoalsList,
    getGoalCycleInfo,
    getGoals,
    createGoal,
    updateGoal,
    deleteGoal,
    updateScore,
    submitGoals,
    withdraw,
    approveGoals,
    rejectGoals,
    getGoalsForSearch,
    getManagerSummary,
    createVersion,
    revertVersion,
    copyGoals,
    getExecutiveSummary,
    approveExecutiveGoals,
    rejectExecutiveGoals,
    logAction,
};
