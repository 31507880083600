import React, { useState, useContext } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import './ImportGoals.scss';
import { IGoalsInfo } from '../../interfaces';
import { FileSpreadsheet, X, InfoCircleFill, CheckCircleFill, XCircleFill, Trash } from 'react-bootstrap-icons';
import { checkUploadedData, useUploadCSVData } from '../../hooks/goalsFileHandler';
import CONSTANTS from '../../constants';
import { EmployeeContext } from '../../GoalsRoutes';
import { useGAEvents } from '../../hooks/useGoogleAnalytics';
import ClipLoader from 'react-spinners/ClipLoader';
import * as XLSX from 'xlsx';
import { logAction } from '../../API/Goals';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
interface Props {
    show: boolean;
    handleShow: (value: boolean) => void;
    goalInfo: IGoalsInfo;
}

type Inputs = {
    file: File[];
};

type Alerts = {
    show: boolean;
    hide: boolean;
    text: string;
    variant: string;
};

const ImportGoals: React.FC<Props> = ({ show, handleShow, goalInfo }: Props) => {
    const {
        register,
        formState: { errors },
        getValues,
        setValue,
        handleSubmit,
    } = useForm<Inputs>();
    const [fileName, setFileName] = useState('');
    const [alert, setAlert] = useState<Alerts[]>([]);
    const [successAlert, setSuccessAlert] = useState<any>();
    const [importedData, setImportedData] = useState<any>();
    const [tableData, setTableData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const employee = useContext(EmployeeContext);
    const uiActionData = {
        employeeId: goalInfo.employeeId,
        year: goalInfo.year,
        quarter: goalInfo.activeQuarter,
        action: '',
        response: '',
        logType: 'UI Action',
    };
    const { triggerEvent } = useGAEvents();
    const handleChange = () => {
        const [file] = getValues('file');
        if (file) {
            setFileName(file.name);
        } else {
            setFileName('');
        }
    };

    const handleOnChange = (e: any) => {
        setAlert([]);
        const importedTable = document.querySelector('.goalList') as HTMLTableElement;
        const rows = importedTable?.rows;
        if (rows && rows.length > 0) {
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                if (row.classList.contains('invalid-row')) {
                    row.classList.remove('invalid-row');
                }
            }
        }
        handleChange();
        const [file] = e.target.files;
        const reader = new FileReader();
        reader.onload = (evt: any) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, rawNumbers: true });
            const importModel = document.querySelector('.modal-dialog');
            importModel?.classList.add('importModalWidth');
            setTableData(data.slice(1, data.length));
            setImportedData(JSON.stringify(data));
        };
        reader.readAsBinaryString(file);
    };

    const onImport = async (data: Inputs) => {
        uiActionData.action = 'Import Goals Clicked';
        const [file] = data.file;
        triggerEvent('USER_IMPORTED_GOALS');
        setIsLoading(true);
        const errorCheck = await checkUploadedData(importedData);
        let response = undefined;
        if ((errorCheck && errorCheck.length === 0) || !errorCheck) {
            response = await useUploadCSVData(file, employee.employeeId, goalInfo.goalId, importedData);
            setIsLoading(false);
            const parentelem = document.querySelector('.import-goals-modal');
            parentelem?.scrollTo({ top: 0 });
            setSuccessAlert({
                show: true,
                hide: false,
                text: response?.message ? response.message : '',
                variant: response?.status == 'SUCCESS' ? 'success' : 'danger',
            });
            if (response?.status == 'SUCCESS') {
                setTimeout(() => {
                    setFileName('');
                    setSuccessAlert({ hide: false, show: false, text: '', variant: '' });
                    setTableData(null);
                    handleShow(false);
                }, 3000);
            }
            uiActionData.response = `Status: ${response?.status}, Message: ${response?.message}`;
            setAlert([]);
            await logAction(goalInfo.goalId, uiActionData);
        } else {
            setIsLoading(false);
            const parentelem = document.querySelector('.import-goals-modal');
            parentelem?.scrollTo({ top: 0 });
            const array: { show: boolean; hide: boolean; text: string; variant: string }[] = [];
            const importedTable = document.querySelector('.goalList') as HTMLTableElement;
            const rows = importedTable.rows;
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                if (row.classList.contains('invalid-row')) {
                    row.classList.remove('invalid-row');
                }
            }
            errorCheck.map((error) => {
                array.push({
                    show: true,
                    hide: false,
                    text: error?.message ? `Row # ${error.rowNo?.toString()} - ${error.message}` : '',
                    variant: error?.status == 'SUCCESS' ? 'success' : 'danger',
                });
                if (error && error.rowNo && error.rowNo.length > 0) {
                    error.rowNo.map((row) => {
                        const invalidRow = importedTable?.rows[row];
                        invalidRow?.classList.add('invalid-row');
                    });
                }
            });
            setAlert([...array]);
        }
    };

    const closeFileHandler = () => {
        setValue('file', []);
        handleChange();
        setTableData(null);
        const importModel = document.querySelector('.modal-dialog');
        importModel?.classList.remove('importModalWidth');
        setAlert([]);
    };

    const handleDeleteData = async (index: number) => {
        setIsLoading(true);
        const importedTable = document.querySelector('.goalList') as HTMLTableElement;
        const invalidRow = importedTable?.rows[index + 1];
        invalidRow?.classList.remove('invalid-row');
        const parsedData = JSON.parse(importedData);
        const updatedData = parsedData.filter((v: any, i: number) => i !== index + 1);
        setTableData(updatedData.slice(1, updatedData.length));
        setImportedData(JSON.stringify(updatedData));
        const errorCheck = await checkUploadedData(JSON.stringify(updatedData));
        setIsLoading(false);
        if (errorCheck && errorCheck.length > 0) {
            const parentelem = document.querySelector('.import-goals-modal');
            parentelem?.scrollTo({ top: 0 });
            const array: { show: boolean; hide: boolean; text: string; variant: string }[] = [];
            const importedTable = document.querySelector('.goalList') as HTMLTableElement;
            const rows = importedTable.rows;
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                if (row.classList.contains('invalid-row')) {
                    row.classList.remove('invalid-row');
                }
            }
            errorCheck.map((error) => {
                array.push({
                    show: true,
                    hide: false,
                    text: error?.message ? `Row # ${error.rowNo?.toString()} - ${error.message}` : '',
                    variant: error?.status == 'SUCCESS' ? 'success' : 'danger',
                });
                if (error && error.rowNo && error.rowNo.length > 0) {
                    error.rowNo.map((row) => {
                        const invalidRow = importedTable?.rows[row];
                        invalidRow?.classList.add('invalid-row');
                    });
                }
            });
            setAlert([...array]);
        } else {
            setAlert([]);
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => handleShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="import-goals-modal"
        >
            <div className="title">
                <h3>Import Goals</h3>
                <a href={`${CONSTANTS.SAMPLE_GOAL_IMPORT_FILE_PATH}`}>Download Template</a>
            </div>
            <Alert variant="warning">Warning: Importing goals will replace existing goals</Alert>
            <div className="upload-file">
                {/* <span>Warning: Importing goals will replace existing goals</span> */}
                <label className={`custom-file-upload ${errors.file ? 'error' : ''}`}>
                    <input
                        type="file"
                        {...register('file', { required: true })}
                        onChange={handleOnChange}
                        accept=".csv, .xlsx"
                    />
                    Browse
                </label>
                {/* <span>* Currently import of owners is not supported</span> */}
                {fileName && (
                    <div className="filename">
                        <div>
                            <FileSpreadsheet />
                            <h3>{fileName}</h3>
                        </div>
                        <X onClick={closeFileHandler} className="close" cursor="pointer" />
                    </div>
                )}
            </div>
            {!(successAlert && successAlert.show && successAlert.variant && successAlert.text) &&
                alert.map((obj: any, index: number) => (
                    <Alert
                        key={index}
                        show={obj.show}
                        variant={obj.variant}
                        onClose={() => setAlert([{ show: false, hide: false, text: '', variant: '' }])}
                        dismissible
                    >
                        {obj.variant == 'danger' && (
                            <XCircleFill style={{ marginRight: '10px', fontSize: '24px', fontWeight: 'bold' }} />
                        )}
                        {obj.variant == 'success' && (
                            <CheckCircleFill style={{ marginRight: '10px', fontSize: '24px', fontWeight: 'bold' }} />
                        )}
                        {obj.variant == 'warning' && (
                            <InfoCircleFill style={{ marginRight: '10px', fontSize: '24px', fontWeight: 'bold' }} />
                        )}
                        {obj.text}
                    </Alert>
                ))}
            {successAlert && successAlert.show && successAlert.variant && successAlert.text && (
                <Alert
                    show={successAlert.show}
                    variant={successAlert.variant}
                    onClose={() => setSuccessAlert({ hide: false, show: false, text: '', variant: '' })}
                    dismissible
                >
                    {successAlert.variant == 'danger' && (
                        <XCircleFill style={{ marginRight: '10px', fontSize: '24px', fontWeight: 'bold' }} />
                    )}
                    {successAlert.variant == 'success' && (
                        <CheckCircleFill style={{ marginRight: '10px', fontSize: '24px', fontWeight: 'bold' }} />
                    )}
                    {successAlert.variant == 'warning' && (
                        <InfoCircleFill style={{ marginRight: '10px', fontSize: '24px', fontWeight: 'bold' }} />
                    )}
                    {successAlert.text}
                </Alert>
            )}
            {tableData && tableData.length > 0 && (
                <Table className="goalList" responsive size="md" borderless striped hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>CATEGORY</th>
                            <th>TARGET</th>
                            <th>OWNER</th>
                            <th>POINTS</th>
                            <th>BINARY</th>
                            <th>100% TARGET</th>
                            <th>0% TARGET</th>
                            <th>CONFIDENTIAL</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData &&
                            tableData.length > 0 &&
                            tableData.map((data: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data[0]}</td>
                                        <td>{data[1]}</td>
                                        <td>{data[2]}</td>
                                        <td>{data[3]}</td>
                                        <td>{data[4]}</td>
                                        <td>{data[5]}</td>
                                        <td>{data[6]}</td>
                                        <td>{data[7]}</td>
                                        <td className="action-buttons">
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip id="tooltip-disabled">
                                                        Delete{' '}
                                                        {<ClipLoader color={'#FFF'} loading={isLoading} size={20} />}
                                                    </Tooltip>
                                                }
                                            >
                                                <Trash
                                                    style={{ fontSize: '20px' }}
                                                    onClick={() => handleDeleteData(index)}
                                                />
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            )}
            <div className="buttons">
                {((tableData && tableData.length <= 0) || !tableData) && (
                    <>
                        <Button variant="secondary" onClick={() => handleShow(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" onClick={handleSubmit(onImport)} disabled={isLoading}>
                            <span>
                                Import
                                {<ClipLoader color={'#FFF'} loading={isLoading} size={20} />}
                            </span>
                        </Button>
                    </>
                )}
                {tableData && tableData.length > 0 && (
                    <>
                        <Button variant="secondary" onClick={closeFileHandler}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" onClick={handleSubmit(onImport)} disabled={isLoading}>
                            <span>
                                Proceed
                                {<ClipLoader color={'#FFF'} loading={isLoading} size={20} />}
                            </span>
                        </Button>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default ImportGoals;
