import { ISelectFilter } from '../interfaces';
import { getWithAuth, reloadPageForUnauthorized } from '../utils';

const getCategory = async (): Promise<ISelectFilter[] | undefined> => {
    try {
        const categoryObj = await getWithAuth(`${process.env.REACT_APP_API_HOST}/v1/category`);
        reloadPageForUnauthorized(categoryObj);
        const categoryList = await categoryObj.json();
        const categories: ISelectFilter[] = [];
        for (const category of categoryList) {
            categories.push({
                value: category.id,
                label: category.name,
            });
        }

        return categories;
    } catch (err) {
        console.log(err);
    }
};

export { getCategory };
