import React from 'react';
import { useNavigate } from 'react-router';
import './NotFound.scss';

const Notfound: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="not-found">
            <div className="circle">
                <h1>404</h1>
            </div>
            <h3>Page Not Found</h3>
            <p>We looked everywhere couldn&apos;t find the page you&apos;re looking for</p>
            <button className="btn btn-primary" onClick={() => navigate('/dashboard')}>
                May be try Dashboard
            </button>
        </div>
    );
};

export default Notfound;
