import React, { useState, useEffect, useRef } from 'react';
import Linkify from 'linkify-react';

import { IGoalAction } from '../../../interfaces';
import './EoqResults.scss';
import { numberRowsInTextarea } from '../../../utils';
import { ExclamationCircle } from '../../../icons/index';
import useAutosizeTextArea from '../useAutosizeTextArea';

interface Props {
    indexKey: number;
    data: string;
    disabled: boolean;
    validate: boolean;
    readonly: boolean;
    dispatch?: React.Dispatch<IGoalAction>;
}
const EoqResults: React.FC<Props> = ({ indexKey, data, disabled, validate, readonly, dispatch }: Props) => {
    const [error, setError] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const searchInput = useRef<HTMLTextAreaElement>(null);
    const [EoqResults, setEoqResults] = useState('');
    useAutosizeTextArea(searchInput.current, EoqResults);
    const handleChange = (value: string) => {
        dispatch &&
            dispatch({
                type: 'CHANGE_COMPLETION_PROOF',
                key: indexKey,
                value: value,
            });
        if (!value) {
            setError(true);
        } else {
            setEoqResults(value);
            setError(false);
        }
    };

    useEffect(() => {
        if (null !== searchInput.current) {
            searchInput.current.rows = numberRowsInTextarea(searchInput.current.scrollHeight);
        }
    }, [data]);

    useEffect(() => {
        if (validate && !data) {
            setError(true);
        }
    }, [validate]);

    useEffect(() => {
        if (null !== searchInput.current) {
            searchInput.current.focus();
        }
    }, [showInput]);

    return (
        <>
            <div className="eoq-result">
                <textarea
                    className={`form-control ${error ? 'invalid' : ''}`}
                    name="completionProof"
                    id="completionProof"
                    value={data}
                    cols={30}
                    rows={1}
                    readOnly={readonly}
                    disabled={disabled}
                    onBlur={() => setShowInput(false)}
                    onChange={(e) => handleChange(e.target.value)}
                    ref={searchInput}
                    hidden={!showInput}
                ></textarea>
                <div
                    hidden={showInput}
                    className={`text ${error ? 'invalid' : ''} ${disabled ? 'disabled' : ''}`}
                    onClick={() => {
                        !disabled && setShowInput(true);
                    }}
                >
                    {error && (
                        <p className="alert-msg">
                            <ExclamationCircle />
                            &nbsp; This field is required
                        </p>
                    )}
                    {!error && (
                        <Linkify tagName="p" options={{ target: { url: '_blank' } }}>
                            {data}
                        </Linkify>
                    )}
                </div>
            </div>
        </>
    );
};

export default EoqResults;
