import React, { useEffect } from 'react';
import base64 from 'base64-js';
import qs from 'qs';
import { Loader } from '../index';
import axios from 'axios';

const Auth: React.FC = () => {
    const handleSignIn = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/v1/auth/PKCEAuthCodeFirstStep`, {
                withCredentials: true,
            });
            const firstStep = response.data.url;
            window.location.href = firstStep;
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        handleSignIn();
    }, []);

    return <Loader />;
};

export default Auth;
