import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { storUrlPathInLocalStorage } from './utils';

interface PrivateProps {
    component: React.FC<any>;
    isAuthenticated: boolean;
    userId: number;
}
const PrivateRoute: React.FC<PrivateProps & RouteProps> = ({
    component: Component,
    isAuthenticated,
    userId,
    ...rest
}: PrivateProps) => {
    storUrlPathInLocalStorage();
    return isAuthenticated && userId !== null ? <Component {...rest} /> : <Navigate to="/signin" />;
};

interface PublicProps {
    component: React.FC<any>;
    isAuthenticated: boolean;
    restricted: boolean;
    handleAuthentication: (token: string) => void;
}

const PublicRoute: React.FC<PublicProps & RouteProps> = ({
    component: Component,
    isAuthenticated,
    restricted,
    handleAuthentication,
}: PublicProps) => {
    const path = localStorage.getItem('path');
    return isAuthenticated && restricted ? (
        <Navigate to={path ? path : '/dashboard'} />
    ) : (
        <Component handleAuthentication={handleAuthentication} />
    );
};

export { PrivateRoute, PublicRoute };
