import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { IGoalAction } from '../../../interfaces';
import CONSTANTS from '../../../constants';
interface Props {
    indexKey: number;
    data: number;
    disabled: boolean;
    validate: boolean;
    readonly: boolean;
    dispatch?: React.Dispatch<IGoalAction>;
}
const Points: React.FC<Props> = ({ indexKey, data, disabled, validate, readonly, dispatch }: Props) => {
    const [pointsWarn, setPointsWarn] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setPointsWarn(data > 20);
    }, [data]);

    const handleChange = (value: string) => {
        let countOfDots = 0;
        let decimals = 0;
        for (let i = 0; i < value.length; i++) {
            if (value[i] == '.') {
                countOfDots++;
                if (countOfDots > 1) {
                    return;
                }
            } else {
                const code = value.charCodeAt(i);
                if (code < 48 || code > 58) {
                    return;
                } else {
                    if (countOfDots == 1) {
                        decimals++;
                        if (decimals > 3) {
                            return;
                        }
                    }
                }
            }
        }
        const decimalValue = value as unknown as number;
        if (Number(decimalValue) > 120 || Number(decimalValue) < 0) {
            return;
        }
        const point = value && decimalValue >= 0 ? decimalValue : 0;
        dispatch &&
            dispatch({
                type: 'CHANGE_POINT',
                key: indexKey,
                value: point,
            });

        if (!value) {
            setError(true);
        } else {
            setError(false);
        }
    };

    useEffect(() => {
        if (validate && !data) {
            setError(true);
        }
    }, [validate]);

    return (
        <>
            {pointsWarn ? (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip-disabled">{CONSTANTS.MESSAGES.MORE_POINTS_WARNING}</Tooltip>}
                >
                    <input
                        className={`form-control ${pointsWarn ? 'warn' : ''} ${error ? 'invalid' : ''}`}
                        name="points"
                        type="text"
                        step="any"
                        required
                        autoComplete="off"
                        value={data}
                        readOnly={readonly}
                        disabled={disabled}
                        onChange={(e) => {
                            handleChange(e.target.value);
                        }}
                    />
                </OverlayTrigger>
            ) : (
                <input
                    className={`form-control ${pointsWarn ? 'warn' : ''} ${error ? 'invalid' : ''}`}
                    name="points"
                    type="text"
                    step="any"
                    required
                    autoComplete="off"
                    value={data}
                    readOnly={readonly}
                    disabled={disabled}
                    onChange={(e) => {
                        handleChange(e.target.value);
                    }}
                />
            )}
        </>
    );
};

export default Points;
