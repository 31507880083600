import React from 'react';

import { IGoalAction } from '../../../interfaces';
import './BinaryTarget.scss';

interface Props {
    indexKey: number;
    data: boolean;
    disabled: boolean;
    validate?: boolean;
    readonly: boolean;
    dispatch?: React.Dispatch<IGoalAction>;
}
const BinaryTarget: React.FC<Props> = ({ indexKey, data, disabled, readonly, dispatch }: Props) => {
    return (
        <input
            className="binary-goal"
            name="binary"
            type="checkbox"
            checked={data}
            readOnly={readonly}
            disabled={disabled}
            onChange={(e) => {
                dispatch &&
                    dispatch({
                        type: 'CHANGE_BINARY',
                        key: indexKey,
                        value: e.target.checked,
                    });
            }}
            style={{ width: '18px', margin: 'auto' }}
        />
    );
};

export default BinaryTarget;
