import { IOwnerSelectFilter, ISelectFilter, IStatusWithResponse } from '../interfaces';
import { getAPIStatus, getWithAuth, postWithAuth, putWithAuth, reloadPageForUnauthorized } from '../utils';
const onLogout = async () => {
    try {
        const res = await postWithAuth(`${process.env.REACT_APP_API_HOST}/v1/auth/logout`, {});
        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        document.cookie.split(';').forEach((c) => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
        });
        localStorage.clear();
        window.location.assign(`${process.env.REACT_APP_LOG_OUT_REDIRECT}`);
    } catch (err) {
        console.log(err);
        window.location.assign(`${process.env.REACT_APP_LOG_OUT_REDIRECT}`);
    }
};

const userAutoSearch = async (query: string, role = ''): Promise<ISelectFilter[] | undefined> => {
    try {
        const usersObj = await getWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/users/search/?query=${query}&role=${role}`,
        );
        reloadPageForUnauthorized(usersObj);
        const usersResponse = await usersObj.json();
        const usersList: IOwnerSelectFilter[] = [];

        for (const user of usersResponse) {
            usersList.push({
                label: user.email,
                value: user.id,
                email: user.email,
            });
        }

        return usersList;
    } catch (err) {
        console.log(err);
    }
};

const submitManagerChange = async (
    employeeId: number,
    managerId: number,
    quarter: string,
    year: number,
): Promise<IStatusWithResponse | undefined> => {
    try {
        const res = await putWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/admin/update-manager?employeeId=${employeeId}&managerId=${managerId}&quarter=${quarter}&year=${year}`,
            {},
        );
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

const submitRevertCompletedGoal = async (
    employeeId: number,
    quarter: string,
    year: number,
): Promise<IStatusWithResponse | undefined> => {
    try {
        const res = await putWithAuth(
            `${process.env.REACT_APP_API_HOST}/v1/admin/revert-completed-goal?employeeId=${employeeId}&quarter=${quarter}&year=${year}`,
            {},
        );
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

const submitDbEmplyeeSync = async (): Promise<IStatusWithResponse | undefined> => {
    try {
        const res = await putWithAuth(`${process.env.REACT_APP_API_HOST}/v1/admin/DB-EmployeeSync`, {});
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

const submitBatchEmplyeeSync = async (): Promise<IStatusWithResponse | undefined> => {
    try {
        const res = await putWithAuth(`${process.env.REACT_APP_API_HOST}/v1/admin/BatchEmployeeSync`, {});
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

const batchNewEmployeeAddtoCycle = async (): Promise<IStatusWithResponse | undefined> => {
    try {
        const res = await putWithAuth(`${process.env.REACT_APP_API_HOST}/v1/admin/BatchNewEmployeeAddToCycle`, {});
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

export {
    userAutoSearch,
    submitManagerChange,
    submitRevertCompletedGoal,
    submitDbEmplyeeSync,
    submitBatchEmplyeeSync,
    batchNewEmployeeAddtoCycle,
    onLogout,
};
