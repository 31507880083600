import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
interface Props {
    closeModal: () => void;
    handleSessionExtension: () => Promise<void>;
    calculateExpiry: () => Promise<number>;
}

const ExpiryModal: React.FC<Props> = ({ closeModal, handleSessionExtension, calculateExpiry }) => {
    const [remainingTime, setRemainingTime] = useState(0);
    useEffect(() => {
        const updateRemainingTime = async () => {
            const sessionExpiry = await calculateExpiry();
            const currentTime = Math.floor(Date.now() / 1000);
            const initialRemainingTime = sessionExpiry - currentTime;
            setRemainingTime(initialRemainingTime);
            const countdown = setInterval(() => {
                setRemainingTime((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(countdown);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
            return () => clearInterval(countdown);
        };
        updateRemainingTime();
    }, []);
    const minutes = Math.floor(remainingTime / 60);
    const seconds = Math.floor(remainingTime % 60);
    return (
        <Modal show={true} onHide={closeModal} className="confirmation-modal">
            <Modal.Header closeButton>
                <Modal.Title>Session Expiration Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Your session is about to expire in {minutes} minutes and {seconds} seconds.
                </p>
                <p>Would you like to extend your session?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSessionExtension}>
                    Yes
                </Button>
                <Button variant="outline-secondary" onClick={closeModal}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExpiryModal;
