import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import GoalsRoutes from './GoalsRoutes';
import { useInitializeGA } from './hooks/useGoogleAnalytics';
import './App.scss';
import { HealthCheck, NotFound, Unauthorized } from './components';
import { ToastContainer } from 'react-toastify';
import 'react-loading-skeleton/dist/skeleton.css';
import FeedbackExportPage from './pages/aprfeedback/FeedbackExport';

const App: React.FC = () => {
    const isAllowed = localStorage.getItem('X-Puppeteer') === '1234567';
    useEffect(() => {
        useInitializeGA();
    }, []);

    return (
        <>
            <Router basename={process.env.PUBLIC_URL} future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
                <Routes>
                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/health-check" element={<HealthCheck />} />
                    <Route path="" element={<Navigate to="/dashboard" replace />} />
                    <Route path="*" element={<GoalsRoutes />} />
                    <Route
                        path="/aprfeedback/export/:aprFeedbackId"
                        element={isAllowed ? <FeedbackExportPage /> : <Navigate to="/unauthorized" replace />}
                    />
                </Routes>
            </Router>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};
export default App;
