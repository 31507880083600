import { ISession } from '../interfaces/Session';
import { deleteWithAuth, getWithAuth, reloadPageForUnauthorized } from '../utils';

const extendSession = async (): Promise<boolean> => {
    try {
        const response = await getWithAuth(`${process.env.REACT_APP_API_HOST}/v1/auth/extend-session`);
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        if (data.newExpiry) {
            localStorage.setItem('session_expiry', data.newExpiry);
        }
        return true;
    } catch (error) {
        console.error('Failed to extend session', error);
        return false;
    }
};
const destroySessionById = async (sessionId: string): Promise<boolean> => {
    try {
        const response = await deleteWithAuth(`${process.env.REACT_APP_API_HOST}/v1/auth/sessions/${sessionId}`);
        reloadPageForUnauthorized(response);
        return response.ok; // Return true if session deleted successfully
    } catch (error) {
        console.error('Error destroying session:', error);
        return false;
    }
};
const fetchActiveSessions = async (userId: number): Promise<ISession[]> => {
    try {
        const response = await getWithAuth(`${process.env.REACT_APP_API_HOST}/v1/auth/sessions/active/${userId}`);

        reloadPageForUnauthorized(response);
        const data = await response.json();
        if (response.ok) {
            return data;
        }
        return [];
    } catch (error) {
        console.error('Error fetching active sessions:', error);
        return [];
    }
};
export { destroySessionById, fetchActiveSessions, extendSession };
